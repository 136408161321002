<template>
    <article class="columns">
      <section class="column">
        <div class="modal" style="width: auto" v-bind:class="{ 'is-active': isShowMultipleDialog }">
            <div class="modal-background"></div>
            <div class="modal-card scrollable" style="width: auto">
                <!-- <form action="" class="box" @submit.prevent="submitForm"> -->
                <header class="modal-card-head">
                    <span class="modal-card-title has-text-centered">
                    MULTIPLE PRODUCT FOUND
                    </span>
                    <button class="delete is-danger" type="button" @click="cancelForm">Cancel</button>
                </header>
                <section class="modal-card-body">
                    <b-table
                        :data="scannedProductsMultiple"
                        :selected.sync="selected"
                        @select="productSelected"
                        hoverable
                        >

                        <template>
                            <b-table-column class="hoverme" field="upc" label="UPC"  v-slot="props">
                                {{ props.row.upc }}
                            </b-table-column>
                            <b-table-column field="upcModifier" label="Modifier"  v-slot="props">
                                {{ zeroPad(props.row.upcModifier,3) }}
                            </b-table-column>
                            <b-table-column field="name" label="Name"  v-slot="props">
                                {{ props.row.name }}
                            </b-table-column>
                            <b-table-column numeric field="price" label="Price"  v-slot="props">
                                {{ props.row.price | formatCurrency }}
                            </b-table-column>
                        </template>
                    </b-table>
                </section>
            </div>
        </div>
      </section>
    </article>

</template>

<script>

export default {
    props: {
        isShowMultipleDialog: Boolean,
        scannedProductsMultiple: Array
    },

    data() {
        return {
            selected: {},
        }
    },

    methods: {

        openDialog() {
            this.$nextTick(()=> {
                this.selected = {}
            })
        },

        cancelForm() {
            this.$emit('cancel-product-multiple')
        },

        productSelected(row) {
            this.$emit('product-selected',row)
        },
    },

}
</script>

<style>

.hoverme:hover  {
    color: #fff;
    background-color: #000;
}

</style>